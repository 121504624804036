<template>
  <div class="m-2 rounded-2xl">
    <div class="py-2 sm:px-6">
      <base-card>
        <PenjualanHeader />
        <div class="flex flex-col">
          <div class="flex justify-end">
            <base-search
              v-model="search"
              @input="debounceSearch"
              placeholder="Cari kode atau nama"
            />
          </div>
          <PenjualanTable
            @page-change="onPageChangeOrder"
            @click-row="changeSale"
          />
        </div>
      </base-card>

      <!-- Modal Detail -->
      <OrderDetailModal
        :visible="openDetail"
        :order="getOrder.data"
        :editable="true"
        :deleteable="true"
        with-bonus
        @close="openDetail = false"
        @refresh="handleRefreshOrders"
        @edit="openEditOrder"
        @delete="confirmationDelete = !confirmationDelete"
        @open-prev-order="handleOpenPrevOrder"
      />
    </div>
    <!--DELETE MODAL CONFIRMATION-->
    <transition name="fade">
      <Modal2
        v-if="confirmationDelete"
        @close-modal="confirmationDelete = false"
        colorheading="bg-red-50"
        colorbody="bg-red-50"
      >
        <template v-slot:heading>
          <h3
            class="text-lg font-semibold leading-6 text-gray-900"
            id="modal-title "
          >
            Hapus faktur penjualan
          </h3>
        </template>
        <template v-slot:body>
          <div
            class="relative flex content-center items-center justify-center py-2"
          >
            <svg
              class="text-red-100"
              width="194"
              height="191"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M108.028 1.515c26.059 5.163 34.467 35.206 50.246 56.51 14.48 19.55 37.067 35.218 35.664 59.477-1.648 28.473-17.071 57.204-43.101 69.053-25.607 11.655-53.413-2.016-79.137-13.413-27.257-12.077-58.095-23.354-67.512-51.561-10.255-30.716-.982-65.558 20.224-90.077C44.76 7.977 77.462-4.541 108.028 1.515Z"
                fill="currentColor"
              />
            </svg>
            <div class="absolute flex w-full justify-center">
              <DocumentSuccess class="h-32" />
            </div>
          </div>
          <h3 class="text-lg leading-6 text-gray-900" id="modal-title">
            Faktur penjualan dengan Nomor
            <span class="font-bold">{{
              getOrder.data.attributes.origin_code
            }}</span>
            akan dihapus. Apakah anda yakin ingin melanjutkan?
          </h3>
        </template>
        <template v-slot:footer>
          <div>
            <button
              @click="deleteOrder"
              class="focus:outline-none w-full shrink justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
            >
              Ya, saya yakin
            </button>
            <button
              @click="confirmationDelete = !confirmationDelete"
              class="bg-white-600 inline-flex w-full justify-center rounded-md px-4 py-2 text-base font-medium text-gray-400 hover:bg-white hover:text-blue-400 sm:text-sm"
            >
              Kembali
            </button>
          </div>
        </template>
      </Modal2>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import dayjs from 'dayjs';
import { StorageService } from '@/services/storage.service';
import Modal2 from '@/components/Modal2.vue';
import { DocumentSuccess } from '@/assets/icons';
import OrderDetailModal from '@/components/order/OrderDetailModal';
import PenjualanHeader from '@/components/penjualan/index/header.vue';
import BaseSearch from '@/components/base/Search.vue';
import PenjualanTable from '@/components/penjualan/PenjualanTable.vue';

export default {
  name: 'Home',
  components: {
    Modal2,
    DocumentSuccess,
    BaseSearch,
    OrderDetailModal,
    PenjualanHeader,
    PenjualanTable,
  },

  data() {
    const origin_warehouse_id = StorageService.getUser().current_warehouse;
    return {
      search: null,
      origin_warehouse_id,
      confirmationDelete: false,
      openDetail: false,
      dataPenjualan: {},
    };
  },

  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      getOrder: 'orders/getOrder',
      isLoading: 'orders/getLoading',
      isLoadingOffice: 'offices/getLoading',
      getUser: 'auth/getUser',
      getOffice: 'offices/getOffice',
    }),
    fetchOrdersParams() {
      return {
        'filter[origin_warehouse_id]': this.origin_warehouse_id,
        'filter[destination_office_category_id]': 4,
        'page[limit]': 5,
        'filter[search]': this.search,
        include: 'order,destination-office,origin-office',
      };
    },
    fetchOrderParams() {
      return {};
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
      fetchOrder: 'orders/fetchOrder',
      fetchOfficeById: 'offices/fetchOfficeById',
      updateOrder: 'orders/updateOrder',
    }),
    getClass(attribut) {
      return {
        'bg-indigo-100 text-indigo-800': attribut === 'Diproses Gudang',
        'bg-blue-100 text-blue-800': attribut === 'Diproses Kasir',
        'bg-yellow-100 text-yellow-800': attribut === 'Draft',
        'bg-green-100 text-green-800': attribut === 'Selesai',
      };
    },
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    debounceSearch: debounce(function () {
      this.fetchOrders(this.fetchOrdersParams);
    }, 300),
    onPageChangeOrder(page) {
      this.fetchOrders({
        ...this.fetchOrdersParams,
        page,
      });
    },
    handleRefreshOrders: function () {
      this.openDetail = false;
      this.fetchOrders({
        ...this.fetchOrdersParams,
      });
    },
    handleOpenPrevOrder: function () {
      this.fetchPrevOrder({
        id: this.getOrder.data.id,
        ...this.fetchOrderParams,
      }).then(() => {
        this.visiblePrevOrderModal = true;
      });
    },
    changeSale(data) {
      this.openDetail = !this.openDetail;
      this.fetchOrder({
        id: data.attributes.order_id,
        ...this.fetchOrderParams,
      });
    },
    validasi(data) {
      const payload = {
        data: {
          type: 'orders',
          id: data.id,
          attributes: {
            is_valid_for_payment: true,
          },
        },
      };
      this.updateOrder(payload).then((response) => {
        if (response) {
          this.openDetail = false;
          this.fetchOrders(this.fetchOrdersParams);
        }
      });
    },
    openEditOrder() {
      if (
        this.getOrder.data.attributes.canceled_from &&
        this.getOrder.data.attributes.canceled_by_user_id
      ) {
        this.confirmationEdit = !this.confirmationEdit;
      } else {
        this.$router.push(
          `/penjualan/transaksi-penjualan/${this.getOrder.data.id}`
        );
      }
    },
    deleteOrder() {
      const payload = {
        data: {
          type: 'orders',
          id: this.getOrder.data.id,
          attributes: {
            deletedAt: new Date(),
          },
        },
      };

      this.updateOrder(payload).then((response) => {
        this.confirmationDelete = !this.confirmationDelete;
        this.deleteStatus = true;
        if (response) {
          this.fetchOrders(this.fetchOrdersParams);
          this.openDetail = !this.openDetail;
        } else {
          this.failed = true;
        }
      });
    },
  },
  created() {
    this.fetchOrders(this.fetchOrdersParams);

    this.fetchOfficeById({ office_id: this.getUser.office_id });

    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
